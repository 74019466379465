import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Logo from "./logo"

const Header = ({ siteTitle }) => (
  <header
    style={{
      marginBottom: `1.75rem`,
      background: `rgb(3, 3, 75)`,
    }}>
    <div
      style={{
        maxWidth: 1024,
        padding: `.2rem 1.5rem`,
      }}>
        <Link
          to="/"
          style={{
            color: `aquamarine`,
            textDecoration: `none`,
          }}>
          <Logo/>
        </Link>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
